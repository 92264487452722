import * as React from 'react';
import { useLayoutEffect } from './useLayoutEffect.mjs';

// Adapted from https://github.com/radix-ui/primitives/blob/main/packages/react/id/src/id.tsx#L8
// Prefixed autogenerated id created by useStableId
const AUTO_GENERATED_ID_PREFIX = 'amplify-id';
// Create a local version of React.useId which will reference React.useId for React 18
// and fallback to noop for React 17 and below
// Note: We use `toString()` to prevent bundlers from trying to `import { useId } from 'react';`
// since it doesn't exist in React 17 and below (prevents https://github.com/aws-amplify/amplify-ui/issues/1154)
const useReactId = 
// disable eslint below to allow usage of casting React to `any`, which ensures that TS
// does not get confused about the existence of `useId` in React 17 and below
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
React['useId'.toString()] || (() => undefined);
let count = 0;
/**
 * Create a uuid to use with amplify fields unless
 * an id is provided
 * @param id user specified id
 * @returns string
 */
const useStableId = (id) => {
    const [stableId, setStableId] = React.useState(useReactId());
    // React versions older than 18 will have client-side ids only
    useLayoutEffect(() => {
        if (!id) {
            setStableId((reactId) => reactId ?? String(count++));
        }
    }, [id]);
    return id ?? (stableId ? `${AUTO_GENERATED_ID_PREFIX}-${stableId}` : '');
};

export { AUTO_GENERATED_ID_PREFIX, useStableId };
