// Breakpoint unit is in pixels
const breakpoints = {
    values: {
        base: 0,
        small: 480,
        medium: 768,
        large: 992,
        xl: 1280,
        xxl: 1536,
    },
    defaultBreakpoint: 'base',
};

export { breakpoints };
