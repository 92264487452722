const plDict = {
    'Account recovery requires verified contact information': 'Odzyskanie konta wymaga zweryfikowanych danych kontaktowych',
    'Back to Sign In': 'Powrót do logowania',
    'Change Password': 'Zmień hasło',
    Changing: 'Zmienianie',
    Code: 'Kod',
    'Confirm Password': 'Potwierdź Hasło',
    'Confirm Sign Up': 'Potwierdź rejestrację',
    'Confirm SMS Code': 'Potwierdź kod SMS',
    'Confirm TOTP Code': 'Potwierdź hasło jednorazowe',
    Confirm: 'Potwierdź',
    'Confirmation Code': 'Kod potwierdzenia',
    Confirming: 'Potwierdzanie',
    'Create a new account': 'Utwórz nowe konto',
    'Create Account': 'Utwórz konto',
    'Creating Account': 'Tworzenie konta',
    'Dismiss alert': 'Odrzuć alert',
    Email: 'E-mail',
    'Enter your code': 'Wprowadź swój kod',
    'Enter your Email': 'Wpisz swój adres e-mail',
    'Enter your phone number': 'Wpisz swój numer telefonu',
    'Enter your username': 'Wprowadź swoją nazwę użytkownika',
    'Forgot your password?': 'Zapomniałeś hasła? ',
    'Hide password': 'Ukryj hasło',
    'It may take a minute to arrive': 'Może to chwilę potrwać',
    Loading: 'Ładowanie',
    'New password': 'Nowe hasło',
    or: 'albo',
    Password: 'Hasło',
    'Phone Number': 'Numer telefonu',
    'Resend Code': 'Wyślij kod ponownie',
    'Reset your password': 'Zresetuj swoje hasło',
    'Reset your Password': 'Zresetuj swoje hasło',
    'Send code': 'Wyślij kod',
    'Send Code': 'Zresetuj hasło',
    Sending: 'Wysyłanie',
    'Setup TOTP': 'Konfiguruj TOTP',
    'Show password': 'Pokaż hasło',
    'Sign in to your account': 'Zaloguj się na swoje konto',
    'Sign In with Amazon': 'Zaloguj z Amazon',
    'Sign In with Apple': 'Zaloguj z Apple',
    'Sign In with Facebook': 'Zaloguj z Facebook',
    'Sign In with Google': 'Zaloguj z Google',
    'Sign In': 'Logowanie',
    'Sign in': 'Zaloguj',
    'Signing in': 'Logowanie',
    Skip: 'Pomiń',
    Submit: 'Wyślij',
    Submitting: 'Wysyłanie',
    Username: 'Nazwa użytkownika',
    'Verify Contact': 'Weryfikacja danych kontaktowych',
    Verify: 'Zweryfikuj',
    // Additional translations provided by customers
    Birthdate: 'Data urodzenia',
    'Family Name': 'Nazwisko',
    'Given Name': 'Pierwsze imię',
    'Middle Name': 'Drugie imię',
    Name: 'Imię i nazwisko',
    Nickname: 'Pseudonim',
    'Preferred Username': 'Preferowana nazwa użytkownika',
    Profile: 'Profil',
    Website: 'Strona internetowa',
    'We Emailed You': 'Wysłaliśmy Ci wiadomość e-mail',
    'We Sent A Code': 'Wysłaliśmy kod',
    'We Texted You': 'Wysłaliśmy Ci wiadomość SMS',
    'Your code is on the way. To log in, enter the code we emailed to': 'Twój kod został wysłany. Aby się zalogować, wprowadź kod wysłany na adres e-mail',
    'Your code is on the way. To log in, enter the code we sent you': 'Twój kod został wysłany. Aby się zalogować, wprowadź wysłany do Ciebie kod',
    'Your code is on the way. To log in, enter the code we texted to': 'Twój kod został wysłany. Aby się zalogować, wprowadź kod wysłany do Ciebie w wiadomości SMS pod numer',
};

export { plDict };
