import { tokens } from './tokens/index.mjs';
import { breakpoints } from './breakpoints.mjs';

const defaultTheme = {
    tokens,
    breakpoints,
    name: 'default-theme',
};

export { defaultTheme };
