/**
 * @internal May be removed in a future version
 * Maps from component style props to React `style` props
 * Note: Primarily needed to map from component style props that don't match CSS Properties directly
 * such as wrap => flexWrap and direction => flexDirection
 */
const ComponentPropsToStylePropsMap = {
    alignContent: 'alignContent',
    alignItems: 'alignItems',
    alignSelf: 'alignSelf',
    area: 'gridArea',
    aspectRatio: 'aspectRatio',
    autoColumns: 'gridAutoColumns',
    autoFlow: 'gridAutoFlow',
    autoRows: 'gridAutoRows',
    backgroundColor: 'backgroundColor',
    backgroundImage: 'backgroundImage',
    basis: 'flexBasis',
    border: 'border',
    borderRadius: 'borderRadius',
    borderColor: 'borderColor',
    borderWidth: 'borderWidth',
    borderStyle: 'borderStyle',
    bottom: 'bottom',
    boxShadow: 'boxShadow',
    color: 'color',
    column: 'gridColumn',
    columnEnd: 'gridColumnEnd',
    columnGap: 'columnGap',
    columnSpan: 'gridColumn',
    columnStart: 'gridColumnStart',
    direction: 'flexDirection',
    display: 'display',
    flex: 'flex',
    fontFamily: 'fontFamily',
    fontSize: 'fontSize',
    fontStyle: 'fontStyle',
    fontWeight: 'fontWeight',
    gap: 'gap',
    grow: 'flexGrow',
    height: 'height',
    justifyContent: 'justifyContent',
    left: 'left',
    letterSpacing: 'letterSpacing',
    lineHeight: 'lineHeight',
    margin: 'margin',
    marginBlock: 'marginBlock',
    marginBlockEnd: 'marginBlockEnd',
    marginBlockStart: 'marginBlockStart',
    marginBottom: 'marginBlockEnd',
    marginInline: 'marginInline',
    marginInlineEnd: 'marginInlineEnd',
    marginInlineStart: 'marginInlineStart',
    marginLeft: 'marginInlineStart',
    marginRight: 'marginInlineEnd',
    marginTop: 'marginBlockStart',
    maxHeight: 'maxHeight',
    maxWidth: 'maxWidth',
    minHeight: 'minHeight',
    minWidth: 'minWidth',
    objectFit: 'objectFit',
    objectPosition: 'objectPosition',
    opacity: 'opacity',
    order: 'order',
    overflow: 'overflow',
    padding: 'padding',
    paddingBlock: 'paddingBlock',
    paddingBlockEnd: 'paddingBlockEnd',
    paddingBlockStart: 'paddingBlockStart',
    paddingBottom: 'paddingBlockEnd',
    paddingInline: 'paddingInline',
    paddingInlineEnd: 'paddingInlineEnd',
    paddingInlineStart: 'paddingInlineStart',
    paddingLeft: 'paddingInlineStart',
    paddingRight: 'paddingInlineEnd',
    paddingTop: 'paddingBlockStart',
    position: 'position',
    resize: 'resize',
    right: 'right',
    row: 'gridRow',
    rowEnd: 'gridRowEnd',
    rowGap: 'rowGap',
    rowSpan: 'gridRow',
    rowStart: 'gridRowStart',
    shrink: 'flexShrink',
    templateAreas: 'gridTemplateAreas',
    templateColumns: 'gridTemplateColumns',
    templateRows: 'gridTemplateRows',
    textAlign: 'textAlign',
    textDecoration: 'textDecoration',
    textTransform: 'textTransform',
    top: 'top',
    transform: 'transform',
    transformOrigin: 'transformOrigin',
    width: 'width',
    whiteSpace: 'whiteSpace',
    wrap: 'flexWrap',
};
/**
 * @internal May be removed in a future version
 */
const ComponentPropsToStylePropsMapKeys = Object.keys(ComponentPropsToStylePropsMap);

export { ComponentPropsToStylePropsMap, ComponentPropsToStylePropsMapKeys };
