const heDict = {
    'Account recovery requires verified contact information': 'שחזור לקוח דורש עוד מידע',
    'Back to Sign In': 'חזור להרשמה',
    'Change Password': 'עדכון סיסמא',
    Changing: 'מעדכן',
    Code: 'קוד',
    'Confirm Password': 'אשר סיסמא',
    'Confirm Sign Up': 'אשר הרשמה',
    'Confirm SMS Code': 'אשר sms קוד',
    'Confirm TOTP Code': 'אשר totp קוד',
    Confirm: 'אישור',
    'Confirmation Code': 'אישור קוד',
    Confirming: 'מאשר',
    'Create a new account': 'צור משתמש חדש',
    'Create Account': 'צור משתמש',
    'Creating Account': 'יצירת משתמש',
    'Dismiss alert': 'הסר התראה',
    Email: 'אימייל',
    'Enter your code': 'הכנס את הקוד',
    'Enter your Email': 'הכנס את המייל שלך',
    'Enter your phone number': 'הכנס את מספר הטלפון שלך',
    'Enter your username': 'הכנס את שם המתמש שלך',
    'Forgot your password?': 'שחכת סיסמא ?',
    'Hide password': 'הסתר סיסמא',
    Loading: 'טוען',
    'New password': 'סיסמא חדשה',
    or: 'אוֹ',
    Password: 'סיסמא',
    'Phone Number': 'מספר טלפון',
    'Resend Code': 'שלח קוד שוב',
    'Reset your password': 'אפס סיסמא',
    'Reset your Password': 'אפס סיסמא',
    'Send code': 'שלח קוד',
    'Send Code': 'שלח קוד',
    Sending: 'שולח',
    'Setup TOTP': 'Setup TOTP',
    'Show password': 'הצג סיסמא',
    'Sign in to your account': 'התחבר לחשבון שלך',
    'Sign In with Amazon': 'Sign In with Amazon',
    'Sign In with Apple': 'Sign In with Apple',
    'Sign In with Facebook': 'Sign In with Facebook',
    'Sign In with Google': 'Sign In with Google',
    'Sign in': 'התחבר',
    'Sign In': 'התחבר',
    'Signing in': 'מתחבר',
    Skip: 'דלג',
    Submit: 'שלח',
    Submitting: 'שולח',
    Username: 'שם משתמש',
    'Verify Contact': 'אמת איש קשר',
    Verify: 'אמת',
};

export { heDict };
